import api from "../../api/index"
import axios from "axios"

// probably shouldn't be here
const cellWidth = "128px"

const statusFromProvisioningStatus = (provisioningStatus) => {
    switch (provisioningStatus) {
        case "TO_BE_DEPROVISIONED":
            return "TO_BE_REMOVED"
        case "DEPROVISIONED":
            return "DEACTIVATED"
        case "PROVISIONED":
            return "ACTIVE"
    }

    return provisioningStatus
}

class AccessGrid {
    constructor(props = {}) {
        this.$api = api
        this.loading = false
        this.headers = []
        this.applications = this.$api.state.base([], { dataMap: {} })
        this.usersAccess = this.$api.state.base([], { dataMap: {} })
        this.changeMatrix = {}
        this.createTasks = true
        this.error = ""
        this.numChanges = {
            total: 0,
            ADD: 0,
            REMOVE: 0,
        }
        this.hasChanges = false

        const usersAccessFilters = [
            this.$api.filters.buildFilter("Lifecycle Status", "status", [])
        ]
        usersAccessFilters[0].selected = [
            this.$api.filters.buildFilterItem("status", "ACTIVE", "", "="),
        ]
        this.usersAccess.filters = usersAccessFilters


        this.localStorageKey = props.localStorageKey || "accessGridChange"
    }
    updateChangeMatrix(userId, appId, value) {
        const user = this.usersAccess.dataMap[userId]
        if (user == null) {
            /*
             * Sometimes the filters are not setup the first time through this component.  In thoses
             * cases, we will potentially have a bunch of access change data from local storage, but
             * none from the backend.  This means that in the above find() call, we won't be able to
             * find the users and applications mentioned in the data from local storage.  In this
             * case, treat the data from the api as our source of truth and don't update the change
             * matrix.
             */
            return
        }

        const app = user[appId]
        const matrixEntry = this.changeMatrix[userId][appId]
        const prevStatus = matrixEntry.status

        if (value) {
            if (!this.shouldAccessBoxBeChecked(app.status)) {
                matrixEntry.status = "ADD"
                matrixEntry.value = true
            } else {
                matrixEntry.status = ""
                matrixEntry.value = true
            }
        } else {
            if (this.shouldAccessBoxBeChecked(app.status)) {
                matrixEntry.status = "REMOVE"
                matrixEntry.value = false
            } else {
                matrixEntry.status = ""
                matrixEntry.value = false
            }
        }

        if (!prevStatus && matrixEntry.status) {
            this.numChanges.total++
            this.numChanges[matrixEntry.status]++
        } else if (prevStatus && !matrixEntry.status) {
            this.numChanges.total--
            this.numChanges[prevStatus]--
        }

        this.hasChanges = !!this.numChanges.total
    }
    handleUpdate(userId, appId, value, save = true) {
        this.updateChangeMatrix(userId, appId, value)

        if (!this.hasChanges) {
            // if all changes have been undone we can delete the saved state
            this.deleteSavedState()
        } else if (save) {
            this.autoSave()
        }

        return value
    }
    undo(userId, appId) {
        const app = this.changeMatrix[userId][appId]

        if (app.status) {
            this.handleUpdate(userId, appId, !app.value)
        }
    }
    clearChanges() {
        for (const userId of Object.keys(this.changeMatrix)) {
            for (const appId of Object.keys(this.changeMatrix[userId])) {
                this.undo(userId, appId)
            }
        }
        this.deleteSavedState()
    }
    selectAllFor(app) {
        this.getUsersAccessData().forEach(ua => {
            if (!this.accessChangeDisabled(ua[app].status)) {
                this.handleUpdate(ua.id, app, true, false)
            }
        })
        this.autoSave()
    }
    unselectAllFor(app) {
        this.getUsersAccessData().forEach(ua => {
            if (!this.accessChangeDisabled(ua[app].status)) {
                this.handleUpdate(ua.id, app, false, false)
            }
        })
        this.autoSave()
    }

    setCreateTasks(createTasks) {
        this.createTasks = createTasks
    }
    getNumChanges(status = "total") {
        return this.numChanges[status]
    }
    getNumApps() {
        return this.applications.data
    }
    getApplication(appId) {
        return this.applications.dataMap[appId]
    }
    getUserAccess(userId) {
        return this.usersAccess.dataMap[userId]
    }
    getUserAccountEmails(userId) {
        return this.usersAccess.dataMap[userId]?.accountEmails || []
    }
    getChangeMatrix() {
        return this.changeMatrix
    }
    getChangeValueOf(userId, appId) {
        return this.changeMatrix[userId][appId].value
    }
    getChangeStatusOf(userId, appId) {
        return this.changeMatrix[userId][appId].status
    }
    accessChangeDisabled(status) {
        if (!status || status === "ACTIVE" || status === "DEACTIVATED") {
            return false
        }
        return true
    }
    // probably rename this as well
    shouldAccessBoxBeChecked(status) {
        return !!status && status !== 'DEACTIVATED'
    }
    getUsersAccessData() {
        return this.usersAccess.data
    }
    userHasChanges(userId, status) {
        for (const appChange of Object.values(this.changeMatrix[userId])) {
            if (appChange.status === status) {
                return true
            }
        }
        return false
    }

    resetChangeMatrix() {
        for (const userId of Object.keys(this.changeMatrix)) {
            for (const appId of Object.keys(this.changeMatrix[userId])) {
                this.changeMatrix[userId][appId].status = ""
                this.changeMatrix[userId][appId].value = this.shouldAccessBoxBeChecked(this.usersAccess.dataMap[userId][appId].status)
            }
        }
    }
    resetNumChanges() {
        this.numChanges.total = 0
        this.numChanges.ADD = 0
        this.numChanges.REMOVE = 0
        this.hasChanges = false
    }
    load() {
        this.loading = true

        return this.$api.get.applications(this.applications, { onSuccess: () => {
            if (!this.applications.data.length) {
                this.loading = false
                this.headers = []
                return
            }

            this.applications.dataMap = this.applications.data.reduce((acc, curr) => ({
                ...acc,
                [curr.id]: curr
            }), {})

            // ReorderTableHeader doesn't reset the header order correctly if we replace the headers from underneath it
            if (!this.headers.length) {
                this.headers = [{
                    text: "User name",
                    value: "name",
                    width: "248px",
                    pinned: true,
                    align: "start",
                }]

                const appHeaders = this.applications.data.map(a => ({
                    text: a.name,
                    value: a.id,
                    application: a,
                    sortable: false,
                    width: cellWidth,
                    align: "center",
                    textFormatter: val => val.status
                })).sort((a, b) => a.text - b.text)

                const integratedAppHeaders = appHeaders.filter(h => h.application.orgApplicationProvisioningConfig)
                const manualAppHeaders = appHeaders.filter(h => !h.application.catalogApplicationId)
                const restAppHeaders = appHeaders.filter(h => !h.application.orgApplicationProvisioningConfig && h.application.catalogApplicationId)

                this.headers = this.headers.concat(integratedAppHeaders, manualAppHeaders, restAppHeaders)
            }

            const baseAppMapJson = JSON.stringify(this.applications.data.reduce((acc, curr) => ({
                ...acc,
                [curr.id]: { status: "", value: false },
            }), {}))

            this.$api.get.usersAccess(this.usersAccess, {
                onSuccess: (response) => {
                    this.usersAccess.data = response.data.map(d => ({
                        ...d,
                        ...d.applications.reduce((acc, curr) => ({
                            ...acc,
                            [curr.applicationId]: { status: statusFromProvisioningStatus(curr.provisioningStatus), value: this.shouldAccessBoxBeChecked(statusFromProvisioningStatus(curr.provisioningStatus)) }
                        }), JSON.parse(baseAppMapJson))
                    }), {})
                    this.usersAccess.dataMap = this.usersAccess.data.reduce((acc, curr) => ({
                        ...acc,
                        [curr.id]: curr
                    }), {})

                    this.changeMatrix = this.usersAccess.data.reduce((acc, curr) => ({
                        ...acc,
                        [curr.id]: curr.applications.reduce((acc, curr) => ({
                            ...acc,
                            [curr.applicationId]: { status: "", value: this.shouldAccessBoxBeChecked(statusFromProvisioningStatus(curr.provisioningStatus)) }
                        }), JSON.parse(baseAppMapJson))
                    }), {})

                    this.resetNumChanges()
                },
                onError: (err) => {
                    console.log(err)
                },
                onComplete: () => {
                    this.loadChanges()
                    this.loading = false
                }
            })
        },
        onError: (err) => {
            this.loading = false
            console.log(err)
        }})
    }
    async save() {
        this.loading = true
        this.error = false

        for (const app of this.applications.data) {
            const appChanges = []
            for (const userId of Object.keys(this.changeMatrix)) {
                const status = this.changeMatrix[userId][app.id].status
                if (status) {
                    appChanges.push({ userId, status })
                }
            }
            if (appChanges.length) {
                await this.applyChanges(app, appChanges)
            }
            if (this.error) {
                break
            }
        }

        if (!this.error) {
            // after successfully applying all changes, we can delete the saved local state until futher changes are made
            this.resetChangeMatrix()
            this.resetNumChanges()
            this.deleteSavedState()
        }

        this.loading = false

        return !this.error
    }
    async applyChanges(app, appChanges) {
        const addApplication = async (application, users) => {
            await axios.post(`/api/v1/application/${application.id}/users`, {
                users: users.map(user => ({
                    id: user.id,
                    needsProvisioning: this.createTasks,
                }))
            })
            .then(() => {
                for (const user of users) {
                    if (this.createTasks) {
                        this.usersAccess.dataMap[user.id][application.id].status = "TO_BE_PROVISIONED"
                    } else {
                        this.usersAccess.dataMap[user.id][application.id].status = "ACTIVE"
                    }
                }
            })
            .catch(err => {
                console.log(err)
                this.error = err
            })
        }

        const removeApplication = async (application, users) => {
            await axios.delete(`/api/v1/application/${application.id}/users`, {
                data: {
                    applicationId: application.id,
                    users: users,
                    removeImmediately: !this.createTasks,
                }
            })
            .then(() => {
                for (const user of users) {
                    if (this.createTasks) {
                        this.usersAccess.dataMap[user.id][application.id].status = "TO_BE_REMOVED"
                    } else {
                        this.usersAccess.dataMap[user.id][application.id].status = ""
                    }
                }
            })
            .catch(err => {
                console.log(err)
                this.error = err
            })
        }

        const addUsers = appChanges.filter(ac => ac.status === "ADD").map(ac => ({ id: ac.userId }))
        const removeUsers = appChanges.filter(ac => ac.status === "REMOVE").map(ac => ({ id: ac.userId }))
        if (addUsers.length) {
            await addApplication(app, addUsers)
        }
        if (removeUsers.length) {
            await removeApplication(app, removeUsers)
        }
    }


    autoSave() {
        localStorage.setItem(this.localStorageKey, JSON.stringify(this.changeMatrix))
    }
    deleteSavedState() {
        localStorage.removeItem(this.localStorageKey)
    }
    loadChanges() {
        try {
            const savedMatrix = JSON.parse(localStorage.getItem(this.localStorageKey)) || {}

            for (const userId of Object.keys(savedMatrix)) {
                if (typeof savedMatrix[userId] !== 'object') {
                    continue
                }
                for (const appId of Object.keys(savedMatrix[userId])) {
                    this.handleUpdate(userId, appId, savedMatrix[userId][appId].value, false)
                }
            }

        } catch (err) {
            console.log("error loading saved state, saving clean state", err)
            this.autoSave()
        }
    }
}

export default AccessGrid
